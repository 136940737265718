<template>
  <div>
    <DiscountExamine />
  </div>
</template>

<script>
import DiscountExamine from './table';

export default {
  name: 'log',
  components: {
    DiscountExamine,
  },
};
</script>
