<script>
import TotalAdjust from '../../discount_goods/form/total_adjust.vue';
import request from '../../../../../../utils/request';

export default {
  extends: TotalAdjust,
  data() {
    return {
      rule: [],
      dictCodeList: {},
    };
  },
  components: {
  },

  methods: {
    afterReload() {
      this.formConfig.selectRow[0].availableCount = this.formConfig.selectRow[0].beforeAvailableCount;
      // this.formConfig.selectRow[0].adjustCount = 0;
      this.setValue(this.formConfig.selectRow[0]);
      this.rule.map((v) => {
        const rowData = v;
        rowData.props.disabled = true;
        if (rowData.field === 'adjustCount' || rowData.field === 'remarks' || rowData.field === 'files') {
          rowData.props.disabled = false;
        }
        return rowData;
      });
      if (this.formConfig.selectRow[0].adjustCountType === '10') {
        this.hiddenFields(false, this.fieldList);
      }
    },
    // 提交
    submit() {
      if (!this.getFormData()) return false;
      const params = {
        ...this.formConfig.selectRow[0],
        ...this.getFormData(),
        uniqueDictCode: 'unique_rebate_code',
      };
      delete params.list;
      const path = '/dms/dms/m/repFeePool/updateBpm';
      params.price = params[this.priceType[0].dictCode];
      request
        .post(path, params)
        .then((res) => {
          if (res.success) {
            this.$emit('onClose');
            this.$emit('onGetList');
          }
        });
    },
  },
};
</script>
