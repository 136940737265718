<script>

import ManualEntry from '../../discount_goods/form/manual_entry_form.vue';
import request from '../../../../../../utils/request';

export default {
  extends: ManualEntry,
  data() {
    return {
      rule: [],
    };
  },

  mounted() {

  },
  methods: {
    afterReload() {
      this.formConfig.selectRow[0].onAccountCount = this.formConfig.selectRow[0].adjustCount;
      this.product = this.formConfig.selectRow;
      this.setValue(this.formConfig.selectRow[0]);
      this.rule.map((v) => {
        const rowData = v;
        rowData.props.disabled = true;
        if (rowData.field === 'onAccountCount' || rowData.field === 'remarks' || rowData.field === 'files') {
          rowData.props.disabled = false;
        }
        return rowData;
      });
      // this.getPrice();

      this.changeCount(this.formConfig.selectRow[0].onAccountCount);
    },
    // 提交
    async submit() {
      const product = [];
      // this.product.map((v) => {
      //   let rowData = v;
      //   rowData = {
      //     ...rowData,
      //     ...this.getFormData(),
      //     price: this.getFieldValue(this.priceType[0].dictCode),
      //     adjustCountType: this.getFormData().adjustType,
      //     adjustFeeType: this.getFormData().adjustType,
      //     productName: v.productName,
      //   };
      //   product.push(rowData);
      //   return rowData;
      // });
      const params = {
        ...this.formConfig.selectRow[0],
        ...this.getFormData(),
        adjustCount: this.getFormData().onAccountCount,
        uniqueDictCode: 'unique_rebate_code',
      };
      const path = '/dms/dms/m/repFeePool/updateBpm';
      request.post(path, params).then((res) => {
        if (res.success) {
          this.$emit('onClose');
          this.$emit('onGetList');
        }
      });
      return true;
    },
  },
};
</script>
