<script>
import TablePage from '@/found/components/table_page';
import Modal from '@/found/components/modal';
// import Form from '../form';
import TotalAdjust from '../form/total_adjust.vue';
import ManualEntry from '../form/manual_entry_form.vue';

export default {
  extends: TablePage,
  components: {
    Modal,
    // Form,
    ManualEntry,
    TotalAdjust,
  },
  data() {
    return {
      formConfig: {},
      configs: {
        tableConfig: {
          idKey: 'code',
        },
      },
      params: {
        bpmStates: [0, 1, 2, 3],
      },
      modalConfig: {
        title: '新增',
        visible: false,
        type: 'Modal',
      },
    };
  },
  async created() {
    await this.getConfigList('discount_examine');
  },
  methods: {
    setColumn(v) {
      const rowData = v;
      if (rowData.field === 'files') {
        rowData.type = 'html';
        rowData.formatter = ({ cellValue }) => {
          if (cellValue && cellValue.length > 0) {
            const list = [];
            cellValue.forEach((val) => {
              list.push(`<a href=${val.url}>${val.fileName}</a>`);
            });
            return list.join(',');
          }
          return '';
        };
      }
      return rowData;
    },
    clickVisible({ val, row }) {
      if (val.code === 'edit') {
        let isShow = false;
        switch (row.adjustType) {
          case 1: isShow = true; break;
          case 2: isShow = true; break;
          case 10: isShow = true; break;
          case 11: isShow = true; break;
          default: isShow = false; break;
        }
        switch (row.bpmState) {
          case 0: isShow = true; break;
          // case 1: isShow = true; break;
          case 3: isShow = true; break;
          default: isShow = false; break;
        }

        return isShow;
      }
      return true;
    },
    modalClick({ val, row }) {
      if (val.code === 'edit') {
        const rowData = {
          ...row,
          adjustCount: Math.abs(row.adjustCount),
          adjustCountType: `${row.adjustCountType}`,
          adjustReason: `${row.adjustReason || ''}`,
        };
        if (
          rowData.adjustCountType === '10'
          || rowData.adjustCountType === '11'
        ) {
          this.modalConfig.title = '编辑总量调整';
          this.formName = 'TotalAdjust';
          this.formConfig.btnCode = 'total_adjust';
          this.formConfig.selectRow = [rowData];
          this.openFull();
        } else if (
          rowData.adjustCountType === '1'
          || rowData.adjustCountType === '2'
        ) {
          this.modalConfig.title = '编辑手动上账';
          this.formName = 'ManualEntry';
          this.formConfig.btnCode = val.code;
          this.formConfig.selectRow = [rowData];
          this.openFull();
        }
      } else if (val.code === 'approval_pass' || val.code === 'approval_reject' || val.code === 'approval_submit') {
        if (this.selection.length === 0) {
          this.$message('请选择一条数据');
          return false;
        }
        let bpmState = '0';
        switch (val.code) {
          case 'approval_pass': bpmState = '2'; break;
          case 'approval_reject': bpmState = '3'; break;
          case 'approval_submit': bpmState = '1'; break;
          default: bpmState = '0';
            break;
        }
        const jsonData = `code=${this.selectRow[0].code}&bpmState=${bpmState}`;
        const data = {
          params: { code: this.selectRow[0].code, bpmState },
          url: `${val.apiUrl}?${jsonData}`,
          title: val.ask,
          msg: val.buttonName,
        };

        this.messageBox(data);
      }
    },
  },
};
</script>
